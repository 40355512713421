/*
    Example
    =======
    $colorBlue: #4c769a;
    $colorBtnText: #fff;
    $fontSize: 13px;
    $footerHeight: 64px;
*/

$colorBlue: #2199e8;
$colorBlue2: #589DA8;
$colorBlue3: #4C9AD6;
$colorGrey1: #ededed;
$colorGrey2: #a1a0a5;
$colorGrey3: #f0f0f0;
$colorGrey4: #eaeaea;
$colorGrey5: #e8e8e8;
$colorGrey6: #f3f9fd;
$colorGrey7: #9a9dac;
$colorGrey8: #666666;
$colorRed1: #E2574C;
$colorRed2: #DE6724;
$colorRed3: #b86662;
$colorPurple: #b86662;
$colorPurple2: #65454c;
$colorOrange: #f0ad4e;
$colorGreen2: #3adb76;
$colorGreen: #82ad4e;
$colorGreen4: #66942c;
$colorGreen5: #5cb85c;
$colorGreen3: #71ac94;
$colorGreen6: #72ac94;
$colorBeige: #fce6e2;
$colorHoverMarker:  #f2dede;
$colorActiveMarker: #fcf8e3;
$colorWhite: #fff;
$colorBlack: #5A5A5A;
$colorBlueDark: #37485c;
$colorGreenDark: #034a1d;

$colorBtn: #b86662;
$colorBtnHover: #65454c;

$colorRed2: #cc0000;
