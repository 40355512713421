.tabs-component-tabs {
  list-style: none;
  border: 0;
  display: flex;
  margin: 0;
}

.tabs-component-tab {
  cursor: pointer;
  width: 33%;
  padding: 5px;
  margin: 2px;
  color: white;
  background-color: #b86662;
  border-bottom: solid 4px #7d4242;
  z-index: 2;
  text-align: center;
  transform: translateY(0);
}

.is-active {
  text-decoration: none;
  color: white;
  background-color: #82ad4e !important;
}

.is-active a{
  text-decoration: none;
  color: white;
}

.tabs-component-tab-a {
  color: inherit;
}

.mce-textbox {
  height: 90% !important;
}

@media  screen and (max-width: 992px) {
  .tabs-component-tabs {
    overflow-x: scroll;
  }
}